import React, { useCallback, useState } from "react";

import { useContext, useEffect, useRef } from "react";
// @types
//
import ChatMessageItem from "./ChatMessageItem";
import { ChatContext } from "../context";
import { Avatar, Button, Stack, Typography } from '@mui/material';
import { Timestamp } from 'firebase/firestore';
import { useWidgetSettingsContext } from '../settings';
import Scrollbar from '../components/scrollbar';
import { mockResponse } from '../mockData';
import { DEFAULT_LOGO } from '../lib/firestore';

interface Message {
  content: string;
  id?: string; // oai message id
  role: any;
  type: any;
  createdAt: any; // firestore.Timestamp
  references?: {
    name: string;
    size: number;
    type: string;
    path: string;
  }[];
  fileIds?: string[];
  assistantData?: any;
}

import useWidgetChat from '../hooks/useWidgetChat';
import { QRCodeCanvas } from 'qrcode.react';

export default function ChatMessageList() {
  const scrollRef = useRef<HTMLDivElement>(null);
  const settings = useWidgetSettingsContext();

  const message = encodeURIComponent('/start');

  const whatsappUrl = `https://wa.me/${settings?.whatsappNumber?.slice(1)}${
    settings?.prefillMessageToStartAssistant ? `?text=${message}` : ''
  }`;

  const isQR = settings.redirectToWhatsapp && settings.whatsappNumber;

  const QrCodeSercion = (
    <Stack
      alignSelf="center"
      height={1}
      justifyContent="center"
      spacing={3}
      alignItems="center"
    >
      <QRCodeCanvas value={whatsappUrl} size={240} />
      <Button
        onClick={() => {
          window.open(whatsappUrl, '_blank');
        }}
        variant="contained"
      >
        Go to WhatsApp
      </Button>
    </Stack>
  );

  const {
    messages: stateMessages,
    status,
    errorMessage,
    editMode,
    chatId,
    leadInfo,
  } = useContext(ChatContext);

  const { data } = useWidgetChat({
    widgetId: settings.widgetId,
    chatId,
  });

  const messages: Message[] = ((data?.messages || []) as Message[])?.filter(
    (item) => item.role !== 'system' && !item.content.includes('leadId value:'),
  );

  useEffect(() => {
    const scrollMessagesToBottom = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollTo({
          top: scrollRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }
    };
    scrollMessagesToBottom();
  }, [stateMessages, status]);

  return (
    <Scrollbar
      scrollableNodeProps={{
        ref: scrollRef,
      }}
      sx={{ p: 2, height: 1 }}
    >
      <Stack
        alignItems="center"
        sx={{
          mb: 4,
        }}
      >
        {/* <Avatar
          alt={settings.name}
          src={(settings.assistantAvatar as any)?.preview || DEFAULT_LOGO}
          sx={{ width: 80, height: 80 }}
        /> */}
        <Typography
          variant="subtitle1"
          textAlign="center"
          sx={{
            mt: 5,
          }}
        >
          {settings.name}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          textAlign="center"
          sx={{
            mt: 0.5,
          }}
        >
          {settings.description}
        </Typography>
      </Stack>

      {isQR ? (
        QrCodeSercion
      ) : (
        <>
          {(messages?.length === 0 ||
            messages[messages?.length - 1]?.content === mockResponse ||
            editMode) && (
            <ChatMessageItem
              message={{
                content: `${settings.startMessage}`,
                role: 'assistant',
                createdAt: Timestamp.now(),
              }}
            />
          )}

          {editMode && leadInfo?.username && (
            <ChatMessageItem
              message={{
                content: `${settings.greetingUser?.replace(
                  '{leadName}',
                  leadInfo?.username || 'John Doe',
                )}`,
                role: 'assistant',
                createdAt: Timestamp.now(),
              }}
            />
          )}

          {messages?.map((message, index) => (
            <ChatMessageItem key={index} message={message} />
          ))}

          {status === 'typing' &&
            messages[messages?.length - 1]?.role === 'user' && (
              <ChatMessageItem
                loadingMessage
                message={{
                  role: 'assistant',
                  content: 'Typing...',
                  createdAt: Timestamp.now(),
                }}
              />
            )}
          {status === 'error' &&
            messages[messages?.length - 1]?.role != 'assistant' && (
              <ChatMessageItem
                error
                message={{
                  role: 'assistant',
                  content: errorMessage,
                  createdAt: Timestamp.now(),
                }}
              />
            )}
        </>
      )}
    </Scrollbar>
  );
}
