"use client";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Timestamp,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { BotStatus, ChatDoc, LeadData, Message } from "./types";
import { useFirestore, useFirestoreDocData } from "reactfire";
import { useWidgetSettingsContext } from "./settings";
import { clearAllConversation, createChatDoc, getMessage } from "./lib/firestore";
import { useMediaQuery, useTheme } from "@mui/material";
import { mockResponse } from "./mockData";
import { getStorage, setStorage } from "./hooks/use-local-storage";
import { widgetDB } from "./lib/firebase";

interface ChatContextType {
  messages: ChatDoc["messages"];
  errorMessage: string;
  sendMessage: (userMessage: string, messages: ChatDoc["messages"]) => void;
  clearConversation: () => void;
  status: BotStatus;
  setStatus: React.Dispatch<React.SetStateAction<BotStatus>>;
  chatOpen: boolean;
  setChatOpen: React.Dispatch<React.SetStateAction<boolean>>;
  editMode?: boolean;
  isHome?: boolean;
  chatId?: string | null | undefined;
  setLeadInfo: React.Dispatch<React.SetStateAction<LeadData | undefined>>;
  setIsSubmittedFirstMessage: React.Dispatch<React.SetStateAction<boolean>>;
  isSubmittedFirstMessage: boolean;
  leadInfo?: LeadData;
}

export const ChatContext = React.createContext<ChatContextType>({
  clearConversation: () => {},
  messages: [],
  sendMessage: () => {},
  status: "online",
  setStatus: () => {},
  chatOpen: false,
  setChatOpen: () => {},
  errorMessage: "",
  chatId: undefined,
  setLeadInfo: () => {},
  setIsSubmittedFirstMessage: () => {},
  isSubmittedFirstMessage: false,
  leadInfo: undefined,
});

interface ChatContextProviderProps {
  children: React.ReactNode;
  editMode?: boolean;
  widgetId?: string;
  defaultOpen?: boolean;
  isHome?: boolean;
}

// ----------------------------------------------------------------------

export const ChatContextProvider: React.FC<ChatContextProviderProps> = ({
  children,
  editMode,
  widgetId,
  defaultOpen,
  isHome,
}) => {
  const [messages, setMessages] = useState<ChatDoc["messages"]>([]);
  const [status, setStatus] = useState<BotStatus>("online");
  const theme = useTheme();
  const [leadInfo, setLeadInfo] = useState<LeadData>();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const [chatOpen, setChatOpen] = useState<boolean>((!!editMode || !!defaultOpen) && isSmUp);
  const DB = widgetDB;
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isSubmittedFirstMessage, setIsSubmittedFirstMessage] =
    useState<boolean>(false);
  const [firstMessage, setFirstMessage] = useState<string>('');

  const widgetSettings = useWidgetSettingsContext();

  const isPaid = !!widgetSettings?.plan;

  const chatRef = doc(
    DB,
    'widgets',
    widgetId || '0',
    'chats',
    getStorage(`user-widget-id-${widgetId}`) || '0',
  );

  const [chatId, setChatId] = useState<string | undefined | null>(
    getStorage(`user-widget-id-${widgetId}`),
  );

  const { data, status: dataStatus } = useFirestoreDocData(chatRef);

  const db = widgetDB;

  const userRef = doc(db, "users", widgetSettings.ownerUid || "1");

  const { data: user } = useFirestoreDocData(userRef);

  const isFreeUser =
    user?.stripe?.priceId === "price_1PTRURERYpQbaFQQuPcJwYiO" || !user?.stripe?.priceId;

  const userLeadsCount = user?.leads?.count || 0;

  const userReachLeadLimit = userLeadsCount >= 2;

  const isHidden = isFreeUser && userReachLeadLimit;

  useEffect(() => {
    if (editMode && !widgetSettings.assistant?.assistantId) {
      return;
    }

    if (data && dataStatus === "success") {
      setChatId(data?.chatId || data?.NO_ID_FIELD);
      if (data?.messages?.length) {
        setMessages(
          [...data.messages].sort((mes1, mes2) => mes1.createdAt.toDate() - mes2.createdAt.toDate())
        );
      }
    }
  }, [data, dataStatus]);

  const sendMessage = useCallback(
    async (userMessage: string, messages: ChatDoc["messages"]) => {
      try {
        console.log("sendMessage", userMessage, messages, data);
        setErrorMessage("");
        setStatus("online");

        if (editMode && !widgetSettings.assistant) {
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              type: "text",
              role: "user",
              content: userMessage,
              createdAt: Timestamp.now(),
            },
          ]);
          setStatus("typing");

          setMessages((prevMessages) => [
            ...prevMessages,
            {
              type: "text",
              role: "assistant",
              content: mockResponse,
              createdAt: Timestamp.now(),
            },
          ]);
          setStatus("online");
          return;
        } else {
          const currentChatId = getStorage(`user-widget-id-${widgetId}`);
          if (!currentChatId && !firstMessage) {
            setFirstMessage(userMessage);
            setIsSubmittedFirstMessage(true);

            return;
          }
          if (
            !currentChatId &&
            widgetSettings.widgetId &&
            widgetSettings.ownerUid &&
            (editMode || leadInfo)
          ) {
            // Create a reference to the collection
            const chatsCollectionRef = collection(DB, `widgets/${widgetId}/chats`);

            // Create the query
            const chatQuery = query(
              chatsCollectionRef,
              where("leadEmail", "==", leadInfo?.email || "email")
            );

            const chatsSnapshot = await getDocs(chatQuery);
            const chats = chatsSnapshot.docs
              .map((doc) => {
                return doc.data() as ChatDoc;
              })
              .sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());

            const lastChat = chats[0];

            if (lastChat) {
              console.log("getMessage lastChat");
              setStorage(`user-widget-id-${widgetId}`, lastChat.chatId);
              setChatId(lastChat.chatId);
              setStatus("typing");
              await getMessage(
                widgetSettings.widgetId,
                lastChat.chatId,
                widgetSettings.assistant?.assistantId || "",
                userMessage,
                lastChat?.messages.length ? lastChat?.messages : [],
                isPaid
              );
              setTimeout(() => {
                setStatus("online");
              }, 300);

              return;
            }
            createChatDoc(
              widgetSettings.widgetId,
              widgetSettings.ownerUid,
              {
                email: leadInfo?.email || '',
                phone: leadInfo?.phone || '',
                username: leadInfo?.username || '',
              },
              widgetSettings.assistant?.assistantId || '',
              isHidden,
            )
              .then((chatId) => {
                setChatId(chatId);
                setStorage(`user-widget-id-${widgetId}`, chatId);

                setStatus('typing');
                const docRef = doc(DB, `widgets/${widgetId}/chats`, chatId);

                if (editMode) {
                  return getMessage(
                    widgetSettings.widgetId!,
                    chatId,
                    widgetSettings.assistant?.assistantId || '',
                    firstMessage,
                    [],
                    isPaid,
                  );
                }

                return setDoc(
                  docRef,
                  {
                    messages: [
                      {
                        type: 'text',
                        role: 'assistant',
                        content: `${
                          widgetSettings.startMessage || 'Hello there!'
                        }`,
                        createdAt: Timestamp.now(),
                      },
                      {
                        type: 'text',
                        role: 'assistant',
                        content: `${widgetSettings.greetingUser?.replace(
                          '{leadName}',
                          leadInfo?.username || 'User',
                        )}`,
                        createdAt: Timestamp.now(),
                      },
                    ],
                  },
                  { merge: true },
                ).then(() =>
                  getMessage(
                    widgetSettings.widgetId!,
                    chatId,
                    widgetSettings.assistant?.assistantId || '',
                    firstMessage,
                    [
                      {
                        type: 'text',
                        role: 'assistant',
                        content: `${
                          widgetSettings.startMessage || 'Hello there!'
                        }`,
                        createdAt: Timestamp.now(),
                      },
                      {
                        type: 'text',
                        role: 'assistant',
                        content: `${widgetSettings.greetingUser?.replace(
                          '{leadName}',
                          leadInfo?.username || 'User',
                        )}`,
                        createdAt: Timestamp.now(),
                      },
                    ],
                    isPaid,
                  ),
                );
              })
              .then(() => {
                setTimeout(() => {
                  setStatus('online');
                }, 300);
              })
              .catch((err: any) => {
                setErrorMessage(err?.message);
                setStatus('error');
              });
          } else {
            if (chatId && widgetSettings.widgetId) {
              setStatus("typing");
              console.log("getMessage chatId && widgetSettings.widgetId");

              console.log("messages", messages, data);

              await getMessage(
                widgetSettings.widgetId,
                chatId,
                widgetSettings.assistant?.assistantId || "",
                userMessage,
                messages || [],
                isPaid
              );
              setTimeout(() => {
                setStatus("online");
              }, 300);
            } else if (editMode || leadInfo) {
              const chatsCollectionRef = collection(DB, `widgets/${widgetId}/chats`);

              const chatQuery = query(
                chatsCollectionRef,
                where("leadEmail", "==", leadInfo?.email || "email")
              );

              const chatsSnapshot = await getDocs(chatQuery);
              const chats = chatsSnapshot.docs
                .map((doc) => {
                  return doc.data() as ChatDoc;
                })
                .sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());

              const lastChat = chats[0];

              if (lastChat) {
                setChatId(lastChat.chatId);
                setStorage(`user-widget-id-${widgetId}`, lastChat.chatId);
                setStatus("typing");
                console.log("getMessage lastChat 2");

                await getMessage(
                  widgetId!,
                  lastChat.chatId,
                  widgetSettings.assistant?.assistantId || "",
                  userMessage,
                  lastChat?.messages.length ? lastChat?.messages : [],
                  isPaid
                );
                setTimeout(() => {
                  setStatus("online");
                }, 300);
                return;
              }
              const newChatId = await createChatDoc(
                widgetSettings.widgetId!,
                widgetSettings.ownerUid!,
                {
                  email: leadInfo?.email || '',
                  phone: leadInfo?.phone || '',
                  username: leadInfo?.username || '',
                },
                widgetSettings.assistant?.assistantId || '',
                isHidden,
              );
              setChatId(newChatId);
              setStorage(`user-widget-id-${widgetId}`, newChatId);
              setStatus("typing");
              console.log("getMessage last");

              await getMessage(
                widgetSettings.widgetId!,
                newChatId,
                widgetSettings.assistant?.assistantId || "",
                firstMessage,
                messages || [],
                isPaid
              );
              setStatus("online");
            }
          }
        }
      } catch (error: any) {
        setErrorMessage(error?.message);
        setStatus("error");
        console.error("Error sending message:", error);
      }
    },
    [messages, status, firstMessage, leadInfo, data, chatId]
  );

  useEffect(() => {
    if (
      leadInfo &&
      widgetSettings.assistant &&
      widgetSettings.widgetId &&
      widgetSettings.ownerUid
    ) {
      sendMessage(firstMessage, messages);
    }
  }, [leadInfo]);

  const clearConversation = useCallback(async () => {
    const id = widgetId || widgetSettings.widgetId;
    const currChatId = getStorage(`user-widget-id-${id}`);
    try {
      if (id && currChatId) {
        await clearAllConversation(id, currChatId);
        setStatus("online");
        setChatId("");
        setLeadInfo(undefined);
        setFirstMessage("");
        setMessages([]);
      }
    } catch (err: any) {
      console.error(err);
      setStatus("error");
      setErrorMessage(err?.message);
    }
  }, [widgetSettings, widgetId, chatId]);

  const context = useMemo(
    () => ({
      errorMessage,
      clearConversation,
      messages,
      sendMessage,
      status,
      setStatus,
      chatOpen,
      setChatOpen,
      editMode,
      isHome,
      chatId,
      setLeadInfo,
      setIsSubmittedFirstMessage,
      isSubmittedFirstMessage,
      leadInfo,
    }),
    [
      chatOpen,
      clearConversation,
      messages,
      sendMessage,
      status,
      editMode,
      isHome,
      errorMessage,
      chatId,
      isSubmittedFirstMessage,
      leadInfo,
    ]
  );

  return <ChatContext.Provider value={context}>{children}</ChatContext.Provider>;
};
